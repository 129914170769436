import request from '@/utils/request'

export function getList(params) {
	return request({
		url: 'project/page',
		method: 'get',
		params
	})
}

export function save(url, params) {
	return request({
		url: url,
		method: 'post',
		data: params
	})
}

export function check(id) {
	return request({
		url: 'project/info/' + id,
		method: 'get'
	})
}
//下载代码前判断
export function downloadStatus(id) {
	return request({
		url: `project/download/validate/${id}`,
		method: 'get'
	})
}


// 确认订单
export function orderConfirm(id) {
	return request({
		url: `project/confirm/${id}`,
		method: 'post',
	})
}
//获取同行单价格
export function getUpdateList(id) {
	return request({
		url: 'project/repair/list/' + id,
		method: 'get'
	})
}
// 部署预览
export function orderDeploy(id) {
	return request({
		url: `project/deploy/${id}`,
		method: 'post',
	})
}
//修改下载已修改文档状态
export function changeModifiedDownloadFlag(id) {
	return request({
		url: 'project/changeModifiedDownloadFlag/' + id,
		method: 'post'
	})
}
//修改下载视频状态
export function changeVideoDownloadFlag(id) {
	return request({
		url: 'project/changeVideoDownloadFlag/' + id,
		method: 'post'
	})
}
//修改下载视频状态
export function instructionDetail() {
	return request({
		url: 'instruction/detail',
		method: 'get'
	})
}
//修改下载视频状态
export function instructionUpdate(data) {
	return request({
		url: 'instruction/update',
		method: 'post',
		data: data
	})
}
// 部署预览
export function videoDownload(id,lang) {
	return request({
		url: `project/video/download/${id}?lang=${lang}`,
		method: 'post'
	})
}
// 部署预览
export function repairedDownload(id,name,num) {
	return request({
		url: `project/repaired/download/${id}?projectName=${name}&num=${num}`,
		method: 'post'
	})
}// 部署预览
export function updateMyCode(id,myCode) {
	return request({
		url: `project/myCode/update/${id}?myCode=${myCode}`,
		method: 'post'
	})
}