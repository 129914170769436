import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)
import Layout from '@/layout'
import Home from '@/views/order/myOrder/index'
import payOrder from '@/views/payOrder/index'
import user from '@/views/system/user/index'

export const constantRoutes = [{
		path: '/',
		name: '项目管理',
		component: Layout,
		redirect: '/project',
		icon: 'project',
		children: [{
				// 这里不设置值，是把main作为默认页面
				path: '/project',
				name: '项目列表',
				component: Home,
				meta: {
					icon: 'project',
					title: 'center',
					affix: true
				}
			},{
				// 这里不设置值，是把main作为默认页面
				path: '/payOrder',
				name: '项目结算',
				component: payOrder,
				hidden: true,
				meta: {
					icon: 'transaction',
					title: 'center',
					affix: true
				}
			}
		],
	},
	{
		path: '/login',
		component: () => import('@/views/login/index'),
		hidden: true
	}
]

const createRouter = () => new Router({
	// mode: 'hash',
	routes: constantRoutes
})

const router = createRouter()

export function resetRouter() {
	const newRouter = createRouter()
	router.matcher = newRouter.matcher
}
const originalPush = Router.prototype.push
//修改原型对象中的push方法
Router.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err)
}
export default router