import request from '@/utils/request'
// 结算列表
export function payOrderPage(params) {
  return request({
    url: 'settlement/page',
    method: 'get',
    params
  })
}
// 结算详情
export function payOrderDetail(id,params) {
  return request({
    url: `settlement/detail/${id}`,
    method: 'get',
    params
  })
}

// 结算二维码列表
export function qrcodeList() {
  return request({
    url: 'qrcode/list',
    method: 'get'
  })
}
// 结算
export function settlement(id) {
  return request({
    url: `settlement/pay/${id}`,
    method: 'post'
  })
}

// 结算
export function payOrderBalance(id) {
  return request({
    url: `payOrder/balance/${id}`,
    method: 'post'
  })
}

