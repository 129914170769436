<template>
	<div>
		<a-form-model layout="inline" :model="listQuery" @submit="search" @submit.native.prevent
			style="margin: 0 0 10px">
			<a-form-model-item label="项目名称">
				<a-input v-model="listQuery.projectName" placeholder="请输入" allowClear style="width: 180px;"></a-input>
			</a-form-model-item>
			<a-form-model-item label="实例编号">
				<a-input v-model="listQuery.schemaCode" placeholder="请输入" allowClear style="width: 180px;"></a-input>
			</a-form-model-item>
			<a-form-model-item label="私有编号" v-if="showSearch">
				<a-input v-model="listQuery.myCode" placeholder="请输入" allowClear style="width: 180px;"></a-input>
			</a-form-model-item>
			<a-form-model-item v-if="showSearch" label="语言框架">
				<a-select v-model="listQuery.lang" style="width: 180px;" placeholder="请选择" allowClear>
					<a-select-option v-for="(item,index) in languageList" :key="index" :value="item.code">
						{{item.value}}
					</a-select-option>
				</a-select>
			</a-form-model-item>
			<a-form-model-item v-if="showSearch" label="数据库">
				<a-select v-model="listQuery.database" style="width: 180px;" placeholder="请选择" allowClear>
					<a-select-option v-for="(item,index) in databaseTypeList" :key="index" :value="item.code">
						{{item.value}}
					</a-select-option>
				</a-select>
			</a-form-model-item>
			<a-form-model-item v-if="showSearch" label="大前端">
				<a-select v-model="listQuery.bigFrontEnd" style="width: 180px;" placeholder="请选择" allowClear>
					<a-select-option v-for="(item,index) in frontEndList" :key="index" :value="item.code">
						{{item.value}}
					</a-select-option>
				</a-select>
			</a-form-model-item>
			<a-form-model-item v-if="showSearch" label="是否结算">
				<a-select v-model="listQuery.confirmFlag" style="width: 180px;" placeholder="请选择" allowClear>
					<a-select-option v-for="(item,index) in yesOrNoList" :key="index" :value="item.code">
						{{item.value}}
					</a-select-option>
				</a-select>
			</a-form-model-item>
			<a-form-model-item v-if="showSearch" label="项目状态">
				<a-select v-model="listQuery.stageName" style="width: 180px;" placeholder="请选择" allowClear>
					<a-select-option v-for="(item,index) in orderStatusList" :key="index" :value="item.value">
						{{item.value}}
					</a-select-option>
				</a-select>
			</a-form-model-item>
			<a-form-model-item v-if="showSearch" label="高校名称">
				<a-input v-model="listQuery.universityName" placeholder="请输入" allowClear
					style="width: 180px;"></a-input>
			</a-form-model-item>
			<a-form-model-item v-if="showSearch" label="录单时间">
				<a-range-picker class="dateRange" @change="createDateChange" format="YYYY-MM-DD"
					:placeholder="['开始时间','结束时间']" style="width: 180px;" />
			</a-form-model-item>
			<a-form-model-item>
				<a-button-group>
					<a-button type="primary" html-type="submit" icon="search" class="add-btn">
						搜索
					</a-button>

					<a-button type="primary" @click="showSearch=!showSearch" :icon="showSearch?'up':'down'"
						class="add-btn">

					</a-button>
				</a-button-group>
			</a-form-model-item>

		</a-form-model>
		<a-button-group style="margin: 0 0 20px;">

			<a-button type="primary" @click="add" class="add-btn">
				录单
			</a-button>
			<!-- <a-button type="primary" @click="edit" :disabled="selRows.length==1&&editJudge()?false:true">
				编辑
			</a-button> -->
			<!-- <a-button type="primary" @click="updateClick" :disabled="selRows.length==1&&updateJudge()?false:true">
				上传修改
			</a-button>
			<a-button type="primary" @click="databaseClick" v-permission="['/order/database']"
				:disabled="selRows.length==1&&databaseJudge()?false:true">
				数据库文档下载
			</a-button> -->
			<a-button type="primary" @click="downloadClick" v-permission="['/order/codeDownload']" class="export-btn"
				:disabled="selRows.length==1&&downloadJudge()?false:true">
				项目下载
			</a-button>
			<a-button type="danger" @click="confirmClick" v-permission="['/order/confirm']" style="border: none;"
				:disabled="selRows.length==1&&confirmJudge()?false:true">
				确认收货
			</a-button>
			<a-button type="primary" @click="exportClick" v-permission="['/order/exportExcel']" class="export-btn">
				结算订单导出
			</a-button>
			<a-button type="primary" @click="instructionClick" v-permission="['/order/instruction']"
				class="confirm-btn">
				使用说明
			</a-button>
			<!-- <a-button type="primary" @click="deployClick" style="margin: 0 10px 0 0" v-permission="['/order/deploy']"
				ghost :disabled="selRows.length==1?false:true">
				部署预览
			</a-button> -->
		</a-button-group>
		<a-table id="orderTable" :columns="columns" :row-key="record => record.projectId" :data-source="list" :components="components"
			:pagination="false" bordered :scroll="{ x: 1300 }" :loading="loading" @change="handleTableChange"
			:customRow="customRow"
			:row-selection="{ selectedRowKeys: selRows2, onChange: handleSelectionChange,type: 'radio' }">
			
			<template slot="myCode" slot-scope="text,record,index">
				<a-input v-if="nowOrderNum==index" v-model="nowMyCode" minlength="1" style="width: 100%"
					:data-id="index" />
				<div v-else
					style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;width: 100%;height: 22px;"
					@dblclick="editMyCode(index,text)" :data-id="index">
					<a-tooltip placement="top">
						<template slot="title">
							<span>{{text}}</span>
						</template>
						{{text}}
					</a-tooltip>
				</div>
			</template>
			<template slot="projectName" slot-scope="text,record,index">

				<div style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;width: 100%;">
					<a-tooltip placement="top">
						<template slot="title">
							<span>{{text}}</span>
						</template>
						{{text}}
					</a-tooltip>
				</div>
			</template>
			<template slot="stageName" slot-scope="text,record,index">
				{{text}}
				<img class="newImg" v-if="record.newFlag==1" src="@/assets/new.png" alt="" width="30">
			</template>
			<template slot="schemaCode" slot-scope="text,record,index">
				{{text?text:'无'}}
			</template>
			<template slot="universityName" slot-scope="text,record,index">
				<div style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;max-width: 220px;">
					<a-tooltip placement="top">
						<template slot="title">
							<span>{{text?text:'无'}}</span>
						</template>
						{{text?text:'无'}}
					</a-tooltip>
				</div>
			</template>
			<!-- <template slot="createDate" slot-scope="text,record,index">
				<div style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">
					<a-tooltip placement="top">
						<template slot="title">
							<span>{{text}}</span>
						</template>
						{{text}}
					</a-tooltip>
				</div>
			</template> -->
			<!-- <template slot="reqDocument" slot-scope="text">
				<div style="text-align: center;">
					<a-button v-if="text" type="primary" @click="downClick(text)" ghost icon="download"
						shape="circle"></a-button>
					<a-button v-else type="primary" disabled icon="stop" shape="circle"></a-button>
				</div>
			</template>
			<template slot="video" slot-scope="text,record,index">
				<div style="text-align: center;">
					<a-button v-if="text" type="primary"
						@click="downVideoClick(record.videoUrl,record.projectId,record.pVideoDownloadFlag)" ghost
						icon="download" shape="circle"></a-button>
					<a-button v-else type="primary" disabled icon="stop" shape="circle"></a-button>
				</div>
			</template>
			<template slot="updateList" slot-scope="text,record,index">
				<a-button type="primary" @click="updateListClick(record.projectId)" ghost icon="eye"
					shape="circle"></a-button>
			</template> -->
			<template slot="operation" slot-scope="text, record, index">
				<div class="editable-row-operations">
					<a-dropdown :trigger="['click']">
						<a class="ant-dropdown-link" @click="e => e.preventDefault()">
							更多操作 <a-icon type="down" />
						</a>
						<a-menu slot="overlay">
							<a-menu-item @click="downDocumentClick(record.reqDocument)"
								:disabled="record.reqDocument?false:true">
								<a-icon :type="record.reqDocument?'download':'stop'" />
								需求文档下载
							</a-menu-item>
							<a-menu-divider />
							<a-menu-item @click="downVideoClick(record.projectId,record.lang)"
								:disabled="record.video?false:true">
								<a-icon :type="record.video?'download':'stop'" />
								演示录像下载
							</a-menu-item>
							<a-menu-divider />
							<a-menu-item @click="updateClick(record)" :disabled="updateJudge(record)?false:true">
								<a-icon :type="updateJudge(record)?'upload':'stop'" />
								返修文档上传
							</a-menu-item>
							<a-menu-divider />
							<a-menu-item @click="updateListClick(record.projectId,record.projectName)">
								<a-icon :type="'eye'" />
								返修历史查看
							</a-menu-item>
							<a-menu-divider />
							<a-menu-item @click="databaseClick(record)" :disabled="databaseJudge(record)?false:true">
								<a-icon :type="databaseJudge(record)?'download':'stop'" />
								数据库文档下载
							</a-menu-item>
						</a-menu>
					</a-dropdown>
				</div>
			</template>
			<!-- <template slot="frontTemplateCode" slot-scope="text,record,index">
				<a-button v-if="text" type="primary" size="small" ghost>{{text}}</a-button>
				<a-button v-else type="primary" size="small" ghost>暂无</a-button>
				<a-button v-if="record.ttPcTemplateCode" type="primary" ghost
					size="small">{{record.ttPcTemplateCode}}</a-button>
			</template>
			<template slot="backTemplateCode" slot-scope="text,record,index">
				<a-button v-if="text" type="primary" size="small" ghost>{{text}}</a-button>
				<a-button v-else type="primary" size="small" ghost>暂无</a-button>
				<a-button v-if="record.boardTemplateCode" type="primary" ghost
					size="small">{{record.boardTemplateCode}}</a-button>
			</template> -->
		</a-table>
		<a-pagination size="small" v-model="listQuery.page" :total="total" :defaultPageSize="listQuery.limit"
			:show-total="total => `共${total}条`" show-less-items style="padding: 20px 0;" @change="getList()" />
		<a-modal :title="'返修需求'" :visible="formVisible" @ok="saveUpdate" @cancel="formVisible=false" cancelText="取消"
			okText="提交" :maskClosable="false">
			<a-form-model ref="form" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
				<a-form-model-item label="返修文档" prop="repairDocument">
					<a-upload-dragger name="file" :multiple="false" :action="action" @change="handleChange">
						<p class="ant-upload-drag-icon">
							<a-icon type="inbox" />
						</p>
						<p class="ant-upload-text">
							将文件拖到此处，或<em>点击上传</em>
						</p>
					</a-upload-dragger>
				</a-form-model-item>
				<a-form-model-item ref="remark" label="备注" prop="remark">
					<a-input v-model="form.remark" minlength="1" type="textarea" />
				</a-form-model-item>
			</a-form-model>
		</a-modal>
		<a-modal :title="'结算订单导出'" :visible="exportVisible" @ok="exportSave" @cancel="exportVisible=false"
			cancelText="取消" okText="导出" :maskClosable="false">
			<a-form-model ref="form" :model="form" :label-col="labelCol" :wrapper-col="wrapperCol">
				<a-form-model-item ref="startDate" label="开始时间" prop="startDate">
					<a-date-picker format="YYYY-MM-DD" placeholder="开始时间" style="width: 160px"
						@change="startDateChange" />
				</a-form-model-item>
				<a-form-model-item label="结束时间" prop="startDate">
					<a-date-picker format="YYYY-MM-DD" placeholder="结束时间" style="width: 160px"
						@change="endDateChange" />
				</a-form-model-item>
			</a-form-model>
		</a-modal>
		<a-modal :title="'返修列表(' + updateListTitle + ')'" :visible="updateListVisible" :footer="null"
			@cancel="updateListVisible=false" :maskClosable="false" width="70%">
			<a-table :columns="updateColumns" :row-key="record => record.repairedId" :data-source="updateList"
				:pagination="false" :loading="loading" @change="handleTableChange2">
				<template slot="index" slot-scope="text,record,index">
					{{index + 1}}
				</template>
				<template slot="repairDocument" slot-scope="text,record,index">
					<a-button :disabled="text?false:true" type="primary" @click="downClick(text)"
						icon="download"></a-button>
				</template>
				<template slot="repairedDocument" slot-scope="text,record,index">
					<a-button type="primary" icon="download" :disabled="record.repairedDocument==1?false:true"
						@click="downModifiedClick(record.repairedId,record.projectName,index + 1)"></a-button>
				</template>
				<template slot="repairStatus" slot-scope="text,record,index">
					<a-tag color="green" v-if="text=='修复完成'">
						{{text}}
					</a-tag>
					<a-tag color="red" v-if="text=='修复中'">
						{{text}}
					</a-tag>
				</template>
				<template slot="codeUrl" slot-scope="text,record,index">
					<a-button :disabled="text?false:true" type="primary" @click="downClick(text)">下载</a-button>
				</template>
			</a-table>
		</a-modal>
		<a-modal :title="'项目录入'" :visible="addVisible" width="80%" @cancel="addCancel" cancelText="取消" okText="确定"
			:maskClosable="false">
			<orderAdd ref="orderAdds" @change="addBack"></orderAdd>
			<template #footer>
				<a-button @click="handleReset">重置</a-button>
				<a-button type="primary" @click="handleOk">保存</a-button>
			</template>
		</a-modal>
		<a-modal :title="'使用说明'" :visible="instructionVisible" width="80%" @cancel="instructionVisible = false"
			cancelText="取消" okText="保存" :maskClosable="false" @ok="instructionSave">
			<a-upload class="avatar-uploader" style="opacity: 0;height: 0;width: 0;" :action="action"
				@change="handleEditorSuccess" :showUploadList="false">
			</a-upload>
			<quill-editor ref="myQuillEditor" v-model="instructionForm.content" class="myQuillEditor"
				:options="editorOption" style="min-height: 200px;" />
		</a-modal>
		<div v-if="exportLoading"
			style="width: 100vw;height: 100vh;position: fixed;top: 0;left: 0;z-index: 9998;background: rgba(255,255,255,.8);">
			<div
				style="position: absolute;left: 0;bottom: 50vh;width: 100%;text-align: center;z-index: 9999;color: #123;">
				<a-spin></a-spin>
				<div>
					正在生成...请稍候
				</div>
			</div>
		</div>
		<div v-if="databaseLoading"
			style="width: 100vw;height: 100vh;position: fixed;top: 0;left: 0;z-index: 9998;background: rgba(255,255,255,.8);">
			<div
				style="position: absolute;left: 0;bottom: 50vh;width: 100%;text-align: center;z-index: 9999;color: #123;">
				<a-spin></a-spin>
				<div>
					数据库文档下载中......，请耐心等候！
				</div>
			</div>
		</div>
		<div v-if="downloadVisible"
			style="width: 100vw;height: 100vh;position: fixed;top: 0;left: 0;z-index: 9998;background: rgba(255,255,255,.8);">
			<div
				style="position: absolute;left: 0;bottom: 50vh;width: 100%;text-align: center;z-index: 9999;color: #123;">
				<a-spin></a-spin>
				<div>
					项目正在打包中，请耐心等待下载完成。
				</div>
			</div>
		</div>
		<div v-if="deployVisible"
			style="width: 100vw;height: 100vh;position: fixed;top: 0;left: 0;z-index: 9998;background: rgba(255,255,255,.8);">
			<div
				style="position: absolute;left: 0;bottom: 50vh;width: 100%;text-align: center;z-index: 9999;color: #123;">
				<a-spin></a-spin>
				<div>
					正在为您部署项目......，请耐心稍等。
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Vue from 'vue'
	import VueDraggableResizable from 'vue-draggable-resizable'
	
	Vue.component('vue-draggable-resizable', VueDraggableResizable)
	import {
		h
	} from 'vue';
	// 权限判断指令
	import permission from '@/directive/permission/index.js'
	import {
		getList,
		check,
		save,
		downloadStatus,
		orderConfirm,
		orderDeploy,
		getUpdateList,
		changeModifiedDownloadFlag,
		changeVideoDownloadFlag,
		instructionDetail,
		instructionUpdate,
		videoDownload,
		repairedDownload,
		updateMyCode
	} from '@/api/order/myOrder'
	import {
		getToken
	} from '@/utils/auth'
	import axios from 'axios'
	import orderAdd from '../orderAdd/index.vue'
	import {
		quillEditor
	} from 'vue-quill-editor'
	export default {
		directives: {
			permission
		},
		data() {
			this.components = {
			      header: {
			        cell: (h, props, children) => {
			          const { key, ...restProps } = props
			          console.log('ResizeableTitle', key)
			          const col = this.columns.find(col => {
			            const k = col.dataIndex || col.key
			            return k === key
			          })
			
			          if (!col || !col.width) {
			            return h('th', { ...restProps }, [...children])
			          }
			
			          const dragProps = {
			            key: col.dataIndex || col.key,
			            class: 'table-draggable-handle',
			            attrs: {
			              w: 10,
			              x: col.width,
			              z: 1,
			              axis: 'x',
			              draggable: true,
			              resizable: false,
			            },
			            on: {
			              dragging: (x, y) => {
			                col.width = Math.max(x, 1)
			              },
			            },
			          }
			          const drag = h('vue-draggable-resizable', { ...dragProps })
			          return h('th', { ...restProps, class: 'resize-table-th' }, [...children, drag])
			        },
			      },
			    }
			var container = [
				['bold', 'italic', 'underline', 'strike'], // toggled buttons
				['blockquote', 'code-block'],

				[{
					'header': 1
				}, {
					'header': 2
				}], // custom button values
				[{
					'list': 'ordered'
				}, {
					'list': 'bullet'
				}],
				[{
					'script': 'sub'
				}, {
					'script': 'super'
				}], // superscript/subscript
				[{
					'direction': 'rtl'
				}], // text direction

				[{
					'size': ['small', false, 'large', 'huge']
				}], // custom dropdown
				[{
					'header': [1, 2, 3, 4, 5, 6, false]
				}],

				[{
					'color': []
				}, {
					'background': []
				}], // dropdown with defaults from theme
				[{
					'font': []
				}],
				[{
					'align': []
				}],
				['link', 'image'],
				['clean'] // remove formatting button
			]
			return {
				editorOption: {
					placeholder: '',
					theme: 'snow', // or 'bubble'
					modules: {
						toolbar: {
							container: container, // 工具栏
							handlers: {
								'image': function(value) {
									if (value) {
										document.querySelector('.avatar-uploader input').click()
									} else {
										this.quill.format('image', false);
									}
								}
							}
						}
					}
				},
				formVisible: false,
				form: {},
				action: process.env.VUE_APP_BASE_API + '/oss/upload',
				list: [],
				listQuery: {
					page: 1,
					limit: 10
				},
				loading: false,
				title: '',
				formName: '用户',
				visible: false,
				confirmLoading: false,
				databaseLoading: false,
				isAdd: true,
				labelCol: {
					span: 4
				},
				wrapperCol: {
					span: 14
				},
				showPassword: false,
				rules: {
					repairDocument: [{
						required: true,
						message: '请上传',
						trigger: 'blur'
					}]
				},
				columns: [{
						title: '私编',
						dataIndex: 'myCode',
						width: 110,
						scopedSlots: {
							customRender: 'myCode'
						},
						sorter: true
					}, {
						title: '项目',
						dataIndex: 'projectName',
						scopedSlots: {
							customRender: 'projectName'
						},
						width: 110,
					},
					{
						title: '语言框架',
						dataIndex: 'lang',
						width: 120,
					},
					{
						title: '数据库',
						dataIndex: 'database',
						width: 100,
					},
					{
						title: '大前端',
						dataIndex: 'bigFrontEnd',
						width: 100,
					},
					{
						title: '项目状态',
						dataIndex: 'stageName',
						width: 150,
						scopedSlots: {
							customRender: 'stageName'
						},
					},
					{
						title: '实例编号',
						dataIndex: 'schemaCode',
						scopedSlots: {
							customRender: 'schemaCode'
						},
						width: 120,
					},
					{
						title: '高校名称',
						dataIndex: 'universityName',
						scopedSlots: {
							customRender: 'universityName'
						},
						width: 100,
					},
					// {
					// 	title: '价格',
					// 	dataIndex: 'price',
					// 	className: 'tableHiden',
					// 	width: '100px'
					// },
					{
						title: '结算',
						dataIndex: 'confirmFlag',
						width: 60
					},
					// {
					// 	title: '合作伙伴',
					// 	dataIndex: 'partnerName',
					// 	className: 'tableHiden',
					// 	width: '120px'
					// },
					{
						title: '交付时间',
						dataIndex: 'deliverDate',
						width: 120,
					},
					{
						title: '录单员',
						dataIndex: 'createUserName',
						width: 100,
					},
					{
						title: '录单时间',
						dataIndex: 'createDate',
						width: 150
					},
					{
						title: '操作',
						dataIndex: 'operation',
						// fixed: 'right',
						scopedSlots: {
							customRender: 'operation'
						},
						width: 120,
					}
				],
				selRows: [],
				selRows2: [],
				exportForm: {},
				exportVisible: false,
				exportLoading: false,
				downloadVisible: false,
				deployVisible: false,
				updateListVisible: false,
				updateList: [],
				updateListLoading: false,
				updateListTitle: '',
				updateColumns: [{
						title: '返修次数',
						dataIndex: 'index',
						scopedSlots: {
							customRender: 'index'
						},
					},
					{
						title: '返修文档',
						dataIndex: 'repairDocument',
						scopedSlots: {
							customRender: 'repairDocument'
						},
					},
					{
						title: '上传时间',
						dataIndex: 'uploadTime',
					},
					{
						title: '已修复文档',
						dataIndex: 'repairedDocument',
						scopedSlots: {
							customRender: 'repairedDocument'
						},
					},
					{
						title: '完成时间',
						dataIndex: 'finishTime',
					},
					{
						title: '返修状态',
						dataIndex: 'repairStatus',
						scopedSlots: {
							customRender: 'repairStatus'
						},
					},
					{
						title: '备注',
						dataIndex: 'remark',
					},
					// {
					// 	title: '代码下载',
					// 	dataIndex: 'codeUrl',
					// 	scopedSlots: {
					// 		customRender: 'codeUrl'
					// 	},
					// 	className: 'tableHiden',
					// },
				],
				addVisible: false,
				total: 0,
				showSearch: false,
				instructionForm: {},
				instructionVisible: false,
				nowOrderNum: -1,
				nowMyCode: '',
				nowOrderNum2: -1,
				movingColumnKey: null,
				elColgroupList: null,
				elScrollTableList: [],
			}
		},

		created() {
			document.body.onclick = (event) => {
				const dataId = event.target.dataset.id
				if (+dataId === +this.nowOrderNum) {
					return false
				} else {
					if (this.nowOrderNum != -1 && (this.list[this.nowOrderNum].myCode != this.nowMyCode)) {
						let that = this
						this.nowOrderNum2 = JSON.parse(JSON.stringify(this.nowOrderNum))
						this.$confirm({
							title: '提示',
							content: `是否修改该订单的私人编号？`,
							type: 'warning',
							cancelText: '取消',
							okText: '确定',
							onOk() {
								updateMyCode(that.list[that.nowOrderNum2].projectId, that.nowMyCode).then(
									res => {
										that.nowOrderNum2 = -1
										that.$message.success('操作成功')
										that.getList()
									})
							},
							onCancel() {},
						})
					}
					this.nowOrderNum = -1
				}
			}
			this.createTables()
			this.getList()
		},
		components: {
			orderAdd,
			quillEditor,
		},
		computed: {
			userId() {
				return this.$store.state.user.userId
			},
			// templateView() {
			// 	return this.$store.state.user.templateView
			// },
			// isAdmin() {
			// 	return this.$store.state.user.isAdmin
			// },
			isPrimary() {
				return this.$store.state.user.isPrimary
			},
			languageList() {
				return this.$store.state.dict.languageList
			},
			databaseTypeList() {
				return this.$store.state.dict.databaseTypeList
			},
			yesOrNoList() {
				return this.$store.state.dict.yesOrNoList
			},
			orderStatusList() {
				return this.$store.state.dict.orderStatusList
			},
			frontEndList() {
				return this.$store.state.dict.frontEndList
			},
			// subDownloadCode() {
			// 	return this.$store.state.user.subDownloadCode
			// }
		},
		methods: {
			createTables() {
				if (this.isPrimary != 0) {
					this.columns.splice(6, 0, {
						title: '价格',
						dataIndex: 'price',
						width: 60
					})

				}
				if (this.userId == 1) {
					this.columns.splice(8, 0, {
						title: '合作伙伴',
						dataIndex: 'partnerName',
						width: 120
					})
				}
				for (let x in this.columns) {
					// if (this.templateView == 1) {
					// 	if (this.columns[x].dataIndex == 'frontTemplateCode' || this.columns[x].dataIndex ==
					// 		'backTemplateCode') {
					// 		delete this.columns[x].className
					// 	}
					// }

					if (this.userId == 1) {
						if (this.columns[x].dataIndex == 'partnerName') {
							delete this.columns[x].className
						}
					}
				}
				// for (let x in this.updateColumns) {
				// 	if (this.subDownloadCode == 1) {
				// 		if (this.columns[x].dataIndex == 'codeUrl') {
				// 			delete this.columns[x].className
				// 		}
				// 	}
				// }
			},
			toggleColumn(dataIndex, visible) {
				this.columns = this.columns.map(col => {
					if (col.dataIndex === dataIndex) {
						col.visible = visible;
					}
					return col;
				});
			},
			editJudge() {
				let code = this.selRows[0].stageCode
				if (code == 3) {
					return true
				}
				return false
			},
			updateJudge(row) {
				let code = row.stageCode
				if (code == 5 || code == 7) {
					return true
				}
				return false
			},

			databaseJudge(row) {
				let schemaCode = row.schemaCode
				if (!schemaCode) {
					return false
				}
				return true
			},
			downloadJudge() {
				return this.selRows[0].confirmFlag == '是' ? true : false
			},
			confirmJudge() {
				let code = this.selRows[0].stageCode
				let flag = this.selRows[0].confirmFlag
				if (flag == '否' && (code == 5 || code == 7)) {
					return true
				}
				return false
			},
			createDateChange(date, e) {
				this.listQuery.startCreateDate = e[0]
				this.listQuery.endCreateDate = e[1]
			},
			handleSelectionChange(record, selected, selectedRows, nativeEvent) {
				this.selRows = selected
				this.selRows2 = record
			},
			search() {
				this.listQuery.page = 1
				this.getList()
			},
			getList() {
				this.loading = true
				this.selRows = []
				this.selRows2 = []
				getList(this.listQuery).then(res => {
					this.total = Number(res.page.total)
					this.list = res.data
					this.loading = false
				})
			},
			editMyCode(e, myCode) {

				this.nowMyCode = myCode
				this.nowOrderNum = e
			},
			handleTableChange(pagination, filters, sorter) {
				if (sorter.order) {
					this.listQuery.sidx = sorter.field
					this.listQuery.order = sorter.order == 'descend' ? 'desc' : 'asc'
				} else {
					delete this.listQuery.sidx
					delete this.listQuery.order
				}
				this.listQuery.page = 1
				this.getList()
			},
			customRow(record, index) {
				return {
					on: {
						click: (e) => {
							if (this.changeInList(record.projectId, this.selRows2) === true) {
								this.selRows2 = [record.projectId]
								this.selRows = [record]
							}
						}
					}
				}
			},
			changeInList(id, arr) {
				for (let x in arr) {
					if (arr[x] == id) {
						return x
					}
				}
				return true
			},
			handleTableChange2() {},
			changeUserStatus(e, row) {
				changeStatus(row.userId).then(response => {
					this.$message.success('操作成功')
					this.getList()
				})
			},
			changeUserQuery(e, row) {
				changeQuery(row.userId).then(response => {
					this.$message.success('操作成功')
					this.getList()
				})
			},
			changeUserDownload(e, row) {
				changeDownload(row.userId).then(response => {
					this.$message.success('操作成功')
					this.getList()
				})
			},
			changeUserDeploy(e, row) {
				changeDeploy(row.userId).then(response => {
					this.$message.success('操作成功')
					this.getList()
				})
			},
			resetForm() {
				this.form = {
					userId: '',
					userName: '', //用户
					realName: '', //真实姓名
					gender: 1, //性别
					password: '', //密码
					rePassword: '', //确认密码
					status: 1, //状态
				}
			},
			add() {
				this.addVisible = true
				this.$nextTick(() => {
					this.$refs.orderAdds.getInfo()
				})
			},
			addBack() {
				this.addCancel()
				this.getList()
			},
			addCancel() {
				this.$refs.orderAdds.restForm()
				this.addVisible = false
			},
			edit() {
				this.addVisible = true
				this.$nextTick(() => {
					this.$refs.orderAdds.getInfo(this.selRows[0])
				})
			},
			handleReset() {
				this.$refs.orderAdds.restForm()
			},
			handleOk() {
				this.$refs.orderAdds.save()
			},
			remove(id = null) {
				let ids = []
				if (id) {
					ids.push(id)
				} else {
					if (!this.selRows.length) {
						this.$message.error('请选择需要删除的数据')
						return false
					} else {
						for (let i in this.selRows) {
							ids = this.selRows
						}
					}
				}
				this.$confirm({
					title: '提示',
					content: `确定删除此${this.formName}，请问确定吗？`,
					cancelText: '取消',
					okText: '确定',
					onOk() {
						remove(ids).then(res => {
							this.$message.success('操作成功')
							this.getList()
						})
					},
					onCancel() {},
				});
			},

			handleCancel() {
				this.visible = false
			},
			exportClick() {
				this.exportForm = {
					startDate: '',
					endDate: ''
				}
				this.exportVisible = true
			},
			startDateChange(date, e) {
				this.exportForm.startDate = e
			},
			endDateChange(date, e) {
				this.exportForm.startDate = e
			},
			exportSave() {
				let that = this
				let url = process.env.VUE_APP_BASE_API + '/project/exportExcel'
				this.exportVisible = false
				this.exportLoading = true
				axios
					.get(url, {
						headers: {
							token: getToken()
						},
						params: that.exportForm,
						responseType: "blob"
					}, {
						timeout: 300000
					})
					.then(res => {
						this.exportLoading = false
						res = res.data || res
						const objectUrl = URL.createObjectURL(res)
						const a = document.createElement('a')
						a.href = objectUrl
						a.download = '结算订单导出.xlsx'
						// a.click()
						// 下面这个写法兼容火狐
						a.dispatchEvent(new MouseEvent('click', {
							bubbles: true,
							cancelable: true,
							view: window
						}))
						window.URL.revokeObjectURL(res)
					}).catch(err => {
						if (err !== 'cancel') {
							this.$message.error('请求出错，请稍后重试')
						}
						this.exportLoading = false
					})
			},
			updateClick(row) {
				this.form = {
					projectId: row.projectId,
					repairDocument: '',
					remark: ''
				}
				this.formVisible = true
			},
			saveUpdate() {
				this.$refs.form.validate(valid => {
					if (valid) {
						save('/project/repair/document/upload', this.form).then(res => {
							this.$message.success('操作成功')
							this.getList()
							this.formVisible = false
						})
					}
				});

			},
			handleChange({
				file,
				fileList
			}) {
				if (file.status !== 'uploading') {
					// 处理上传完成后的结果
					this.form.repairDocument = file.response.data
				}
			},
			databaseClick(row) {
				let that = this
				this.$confirm({
					title: '提示',
					content: '是否下载数据库文档？',
					type: 'warning',
					cancelText: '取消',
					okText: '下载',
					onOk() {
						let url = process.env.VUE_APP_BASE_API + '/project/database/document?schemaCode=' + row
							.schemaCode
						that.databaseLoading = true
						axios
							.get(url, {
								headers: {
									token: getToken()
								},
								responseType: "blob"
							}, {
								timeout: 300000
							})
							.then(res => {
								that.databaseLoading = false
								let content = res.data;
								const blob = new Blob([content], {
									type: 'application/msword'
								});
								const fileName = row.schemaCode +
									'数据库文档.doc';
								let elink = document.createElement('a')
								if ('download' in elink) {
									elink.download = fileName;
									elink.style.display = 'none';
									elink.href = URL.createObjectURL(blob);
									document.body.appendChild(elink)
									elink.click()
									window.URL.revokeObjectURL(elink.href)
								} else {
									navigator.msSaveBlob(blob, fileName)
								}
							}).catch(err => {
								if (err !== 'cancel') {
									that.$message.error('请求出错，请稍后重试')
								}
								that.databaseLoading = false
							})
					},
					onCancel() {},
				})
			},
			downloadClick() {
				let that = this
				this.$confirm({
					title: '提示',
					content: '确定下载项目？',
					type: 'warning',
					cancelText: '取消',
					okText: '下载',
					onOk() {
						that.downloadClick2()
					},
					onCancel() {},
				})

			},
			downloadClick2(type = 1) {
				let that = this
				downloadStatus(that.selRows[0].projectId).then(obj => {
					let url = process.env.VUE_APP_BASE_API + '/project/download/' + that
						.selRows[0]
						.projectId
					that.downloadVisible = true
					axios
						.get(url, {
							headers: {
								token: getToken()
							},
							responseType: "blob"
						}, {
							timeout: 300000
						})
						.then(res => {
							that.downloadVisible = false
							res = res.data || res
							const objectUrl = URL.createObjectURL(res)
							const a = document.createElement('a')
							a.href = objectUrl
							a.download = that.selRows[0].projectName + '(编号：' + that.selRows[0].schemaCode +
								')' + '.zip'


							// a.click()
							// 下面这个写法兼容火狐
							a.dispatchEvent(new MouseEvent('click', {
								bubbles: true,
								cancelable: true,
								view: window
							}))
							window.URL.revokeObjectURL(res)
							if (type == 2) {
								that.getList()
							}
						}).catch(err => {
							if (err !== 'cancel') {
								that.$message.error('请求出错，请稍后重试')
							}
							that.downloadVisible = false
						})
				})
			},
			confirmClick() {
				let that = this
				this.$confirm({
					title: '提示',
					content: `项目【${that.selRows[0].projectName}】确认收货后，无法撤回，是否继续？`,
					type: 'warning',
					cancelText: '取消',
					okText: '确认收货',
					onOk() {
						orderConfirm(that.selRows[0].projectId).then(res => {
							that.$message.success('操作成功')
							that.$confirm({
								title: '提示',
								content: `项目【${that.selRows[0].projectName}】已确认收货，是否下载项目？`,
								type: 'warning',
								cancelText: '取消',
								okText: '下载',
								onOk() {
									that.downloadClick2(2)
								},
								onCancel() {
									that.getList()
								},
							})

						})
					},
					onCancel() {},
				})
			},
			deployClick() {
				let that = this
				this.$confirm({
					title: '提示',
					content: `确定部署预览该项目？`,
					type: 'warning',
					cancelText: '取消',
					okText: '确定',
					onOk() {
						that.deployVisible = true
						orderDeploy(that.selRows[0].projectId).then(res => {
							that.deployVisible = false
							that.$message({
								type: 'success',
								message: '操作成功',
								duration: 500,
								onClose: () => {
									that.$router.push('../orderCodegen')
								}
							})
						}, err => {
							that.deployVisible = false
						})
					},
					onCancel() {},
				})
			},
			//需求下载
			downClick(attachment) {
				if (attachment) {
					window.open(attachment)
				}
			},
			downDocumentClick(attachment) {
				if (attachment) {
					this.$confirm({
						title: '提示',
						content: `是否下载需求文档？`,
						type: 'warning',
						cancelText: '取消',
						okText: '下载',
						onOk() {
							window.open(attachment)
						},
						onCancel() {},
					})

				}
			},
			downVideoClick(projectId, lang) {
				this.$confirm({
					title: '提示',
					content: `是否下载演示录像？`,
					type: 'warning',
					cancelText: '取消',
					okText: '下载',
					onOk() {
						videoDownload(projectId, lang).then(res => {
							if (res.data) {
								window.open(res.data)
							}
						})
					},
					onCancel() {},
				})

			},
			updateListClick(id = null, name) {
				if (id) {
					this.updateListLoading = true
					getUpdateList(id).then(res => {
						this.updateListTitle = name
						this.updateList = res.data
						this.updateListVisible = true
						this.updateListLoading = false
					})
				}
			},
			downModifiedClick(id, name, num) {
				this.$confirm({
					title: '提示',
					content: `是否下载已修复文档？`,
					type: 'warning',
					cancelText: '取消',
					okText: '下载',
					onOk() {
						repairedDownload(id, name, num).then(res => {
							if (res.data) {
								window.open(res.data)
							}
						})
					},
					onCancel() {},
				})

			},
			instructionClick() {
				instructionDetail().then(res => {
					this.instructionForm = res.data
					this.instructionVisible = true
				})
			},
			instructionSave() {
				instructionUpdate(this.instructionForm).then(res => {
					this.$message.success('操作成功')
					this.instructionVisible = false
				})
			},
			handleEditorSuccess({
				file,
				fileList
			}) {
				if (file.status == 'done') {
					// 处理上传完成后的结果
					let quill = this.$refs.myQuillEditor.quill;

					// 如果上传成功
					if (file.response.code === 200) {
						// 获取光标所在位置
						let length = quill.getSelection().index;
						// 插入图片  res.url为服务器返回的图片地址
						quill.insertEmbed(length, "image", file.response.data);
						// 调整光标到最后
						quill.setSelection(length + 1);
					} else {
						this.$message.error("图片插入失败");
					}
				}

			},
		}
	}
</script>

<style lang="scss" scoped>
	.tableHiden {
		display: none;
	}

	.ant-calendar-picker-input {
		font-size: 12px !important;
	}

	/deep/.ant-table-thead>tr>th {
		// text-align: center;
		font-size: 13px;
		color: #fff;
		background-color: #0083E6;
	}

	/deep/.ant-table-tbody>tr:nth-of-type(2n) {
		background-color: rgba(244, 244, 244, 1);
	}

	/deep/.add-btn {
		background: #0083E6;
		border: none;
	}

	/deep/.add-btn:hover {
		background: #0083E6;
		opacity: 0.5;
	}

	/deep/.confirm-btn {
		background: #33D1FF;
		border: none;
	}

	/deep/.confirm-btn:hover {
		background: #33D1FF;
		opacity: 0.5;
	}

	/deep/.export-btn {
		background: #91CC75;
		border: none;
	}

	/deep/.export-btn:hover {
		background: #91CC75;
		opacity: 0.5;
	}

	/deep/.export-btn:disabled {
		background: #f5f5f5;
		color: rgba(0, 0, 0, .25);
	}

	.dateRange /deep/.ant-calendar-picker-input {
		padding: 0;
	}

	.newImg {
		opacity: 1;
		animation: fadeIn 2s linear 2s infinite;
	}

	@keyframes fadeIn {
		to {
			opacity: .5;
		}

		from {
			opacity: 1;
		}
	}

	/deep/.ant-table-thead>tr>th.ant-table-column-sort {
		color: #0083E6;
		font-weight: bold;
	}

	/deep/.ant-table-thead>tr>th .ant-table-header-column .ant-table-column-sorters:hover {
		color: #0083E6;
		font-weight: bold;
	}
	/deep/.ant-table-column-sorters {
		position: relative;
	}
	/deep/.resize-table-th {
	  position: relative;
	}
	/deep/.table-draggable-handle {
	  /* width: 10px !important; */
	  height: 100% !important;
	  left: auto !important;
	  right: -5px;
	  cursor: col-resize;
	  touch-action: none;
	  border: none;
	  position: absolute;
	  top: 0;
	  transform: none !important;
	}
</style>