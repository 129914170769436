import {
	login,
	logout,
	getInfo,
	getCode,
	register,
	passwd,
	getResetCode,
	getTransToken
} from '@/api/user'
import {
	getToken,
	setToken,
	removeToken
} from '@/utils/auth'
import {
	resetRouter
} from '@/router'
import {
	listForRouter
} from '@/api/menu'

const state = {
	token: getToken(),
	name: '',
	avatar: '',
	permissions: null,
	roles: [],
	orgId: '',
	orgName: '',
	userId: '',
	isPrimary: '',
	roleType: 0,
	styleAttr: {},
	styleOption: [],
	TransToken: '',
	iconOption: [],
	profile: null,
	settlementDate: localStorage.getItem('settlementDate') ? localStorage.getItem('settlementDate') : 0,
	download: localStorage.getItem('download') ? localStorage.getItem('download') : 0
}

const mutations = {
	SET_TOKEN: (state, token) => {
		state.token = token
	},
	SET_NAME: (state, name) => {
		state.name = name
	},
	SET_PROFILE: (state, profile) => {
		state.profile = profile
	},
	SET_PERMISSIONS: (state, permissions) => {
		state.permissions = permissions
	},
	SET_ORGNAME: (state, orgName) => {
		state.orgName = orgName
	},
	SET_USERID: (state, id) => {
		state.userId = id
	},
	SET_MasterAccount: (state, isPrimary) => {
		state.isPrimary = isPrimary
	},
	SET_SETTLEMENTDATE: (state, settlementDate) => {
		state.settlementDate = settlementDate
	},
	SET_ROLES: (state, roles) => {
		state.roles = roles
	},
	SET_DOWNLOAD: (state, download) => {
		state.download = download
	},
}

const actions = {
	// icon attr
	getIconAttr({
		commit,
		state
	}) {
		return new Promise((resolve, reject) => {
			let url = ''
			let link = document.getElementsByTagName("link")
			for (let i in link) {
				if (typeof link[i] == 'object' && link[i].getAttribute('icon')) url = link[i].getAttribute(
					'href')
			}
			// commit('SET_ICON_OPTION', url)
			// resolve(url)
			var x = new XMLHttpRequest();
			x.open('GET', url, true);
			x.onreadystatechange = () => {
				if (x.readyState == 4) {
					if (x.status == 200 || x.status == 0) {
						let text = x.responseText.match(/.icon-\w*-*\w*:before/igm)
						for (let i in text) {
							text[i] = text[i].replace('.icon-', 'icon-')
							text[i] = text[i].replace(':before', '')
						}

						commit('SET_ICON_OPTION', text)
						resolve()
					}
				}
			}
			x.send();
		})
	},


	// user login
	login({
		dispatch,
		commit
	}, userInfo) {
		return new Promise((resolve, reject) => {
			login(userInfo).then(response => {
				const {
					data
				} = response
				localStorage.setItem('profile', JSON.stringify(data))
				if(data.settlementDate) {
					commit('SET_SETTLEMENTDATE',data.settlementDate)
					localStorage.setItem('settlementDate',data.settlementDate)
				}
				
				commit('SET_TOKEN', response.token)
				commit('SET_DOWNLOAD', data.download)
				localStorage.setItem('download',data.download)
				setToken(response.token)
				resolve(data)
			}).catch(error => {
				reject(error)
			})
		})
	},

	// get user info
	getInfo({
		commit,
		state
	}, userInfo) {
		let user = JSON.parse(localStorage.getItem('profile'))
		commit('SET_USERID', user.userId)
		commit('SET_NAME', user.fullName)
		commit('SET_ROLES', [user.userName])
		commit('SET_PROFILE', user)
		commit('SET_MasterAccount', user.isPrimary)

	},
	getTransToken({
		commit,
		state
	}) {
		return new Promise((resolve, reject) => {
			getTransToken().then(res => {
				commit('SET_TransToken', res.accessToken)
				resolve()
			})
		})
	},
	// user logout
	logout({
		commit,
		state
	}) {
		return new Promise((resolve, reject) => {
			logout(state.token).then(() => {
				commit('SET_TOKEN', '')
				commit('SET_NAME', '')
				commit('SET_AVATAR', '')
				commit('SET_PROFILE', {})
				commit('SET_ROLES', [])
				commit('SET_PERMISSIONS', [])
				commit('SET_SETTLEMENTDATE', 0)
				localStorage.removeItem('settlementDate')
				removeToken()
				resetRouter()
				resolve()
			}).catch(error => {
				reject(error)
			})
		})
	},

	// remove token
	resetToken({
		commit
	}) {
		return new Promise(resolve => {
			commit('SET_TOKEN', '')
			removeToken()
			resolve()
		})
	},
	updateToken({
		commit
	}, {
		token
	}) {
		commit('SET_TOKEN', token)
		setToken(token)
	},

	// get code
	getCode({
		commit
	}, phone) {
		return new Promise((resolve, reject) => {
			getCode({
				phone: phone
			}).then(response => {
				const {
					data
				} = response

				resolve()
			}).catch(error => {
				reject(error)
			})
		})
	},

	// get reset code
	getResetCode({
		commit
	}, phone) {
		return new Promise((resolve, reject) => {
			getResetCode({
				phone: phone
			}).then(response => {
				const {
					data
				} = response

				resolve()
			}).catch(error => {
				reject(error)
			})
		})
	},

	// user register
	register({
		dispatch,
		commit
	}, data) {
		return new Promise((resolve, reject) => {
			register(data).then(response => {
				const {
					data
				} = response
				resolve()
			}).catch(error => {
				reject(error)
			})
		})
	},

	// reset passwords
	passwd({
		dispatch,
		commit
	}, data) {
		return new Promise((resolve, reject) => {
			passwd(data).then(response => {
				const {
					data
				} = response
				resolve()
			}).catch(error => {
				reject(error)
			})
		})
	},
}

export default {
	namespaced: true,
	state,
	mutations,
	actions
}