<template>
	<div>
		<a-button-group style="margin: 0 0 20px;">


		</a-button-group>
		<a-table v-permission="['/payOrder']" :columns="columns" :row-key="record => record.settlementId" :data-source="list" :pagination="false"
			bordered :scroll="{ x: 1300 }" :loading="loading">
			<template slot="startDate" slot-scope="text,record,index">
				{{ record.startDate }} 至 {{ record.endDate }}
			</template>
			<template slot="amount" slot-scope="text,record,index">
				￥{{text}}
			</template>
			<template slot="isPay" slot-scope="text,record,index">
				{{text==1?'已支付':'未支付'}}
			</template>
			<template slot="confirmFlag" slot-scope="text,record,index">
				<div v-if="record.isPay" :style="{'color':text!=1?'red':''}">{{text==1?'已确认':'待确认'}}</div>
			</template>
			<template slot="operation" slot-scope="text, record, index">
				<div class="editable-row-operations">
					<a-button v-if="statusJudge(record)" v-permission="['/payOrder/settlement']"
						type="danger" @click="payClick(record.settlementId)" size="small">
						支付
					</a-button>
					<a-button v-permission="['/payOrder/detail']" type="primary"
						@click.native="detailClick(record.settlementId)" size="small">
						项目明细
					</a-button>
				</div>
			</template>
		</a-table>
		<a-pagination v-permission="['/payOrder']" size="small" v-model="listQuery.page" :total="total" :defaultPageSize="listQuery.limit"
			:show-total="total => `共${total}条`" show-less-items style="padding: 20px 0;" @change="getList()" />
		<a-modal :title="'项目明细'" :visible="detailVisible" :footer="null" @cancel="detailVisible=false"
			:maskClosable="false" width="80%">
			<a-form-model layout="inline" :model="listQuery2" @submit="search" @submit.native.prevent
				style="margin: 0 0 10px">
				<a-form-model-item label="项目名称">
					<a-input v-model="listQuery2.projectName" placeholder="请输入" allowClear
						style="width: 180px;"></a-input>
				</a-form-model-item>
				<a-form-model-item label="实例编号">
					<a-input v-model="listQuery2.schemaCode" placeholder="请输入" allowClear
						style="width: 180px;"></a-input>
				</a-form-model-item>
				<a-form-model-item v-if="showSearch" label="高校名称">
					<a-input v-model="listQuery2.universityName" placeholder="请输入" allowClear
						style="width: 180px;"></a-input>
				</a-form-model-item>
				<a-form-model-item label="私有编号" v-if="showSearch">
					<a-input v-model="listQuery2.myCode" placeholder="请输入" allowClear style="width: 180px;"></a-input>
				</a-form-model-item>
				<a-form-model-item v-if="showSearch" label="语言框架">
					<a-select v-model="listQuery2.lang" style="width: 180px;" placeholder="请选择" allowClear>
						<a-select-option v-for="(item,index) in languageList" :key="index" :value="item.code">
							{{item.value}}
						</a-select-option>
					</a-select>
				</a-form-model-item>
				<a-form-model-item v-if="showSearch" label="数据库">
					<a-select v-model="listQuery2.database" style="width: 180px;" placeholder="请选择" allowClear>
						<a-select-option v-for="(item,index) in databaseTypeList" :key="index" :value="item.code">
							{{item.value}}
						</a-select-option>
					</a-select>
				</a-form-model-item>
				<a-form-model-item v-if="showSearch" label="大前端">
					<a-select v-model="listQuery2.bigFrontEnd" style="width: 180px;" placeholder="请选择" allowClear>
						<a-select-option v-for="(item,index) in frontEndList" :key="index" :value="item.code">
							{{item.value}}
						</a-select-option>
					</a-select>
				</a-form-model-item>
				<a-form-model-item>
					<a-button-group>
						<a-button type="primary" html-type="submit" icon="search" class="add-btn">
							搜索
						</a-button>

						<a-button type="primary" @click="showSearch=!showSearch" :icon="showSearch?'up':'down'"
							class="add-btn">

						</a-button>
					</a-button-group>
				</a-form-model-item>

			</a-form-model>
			<a-table :columns="columns2" :row-key="record => record.projectId" :data-source="list2" :pagination="false" @change="handleTableChange"
				bordered :scroll="{ x: 1300 }" :loading="loading2">
				<template slot="myCode" slot-scope="text,record,index">
					<div style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;width: 100%;height: 22px;">
						<a-tooltip placement="top">
							<template slot="title">
								<span>{{text}}</span>
							</template>
							{{text}}
						</a-tooltip>
					</div>
				</template>
				<template slot="projectName" slot-scope="text,record,index">
					<div style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;max-width: 200px;">
						<a-tooltip placement="top">
							<template slot="title">
								<span>{{text}}</span>
							</template>
							{{text}}
						</a-tooltip>
					</div>
				</template>
				<template slot="price" slot-scope="text,record,index">
					￥{{text}}
				</template>
				<template slot="stageName" slot-scope="text,record,index">
					{{text}}
					<img class="newImg" v-if="record.newFlag==1" src="@/assets/new.png" alt="" width="30">
				</template>
				<template slot="schemaCode" slot-scope="text,record,index">
					{{text?text:'无'}}
				</template>
				<template slot="confirmTime" slot-scope="text,record,index">
					{{text}}
					{{record.confirmUserName?`(${record.confirmUserName})`:''}}
				</template>
				<template slot="universityName" slot-scope="text,record,index">
					<div style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;max-width: 220px;">
						<a-tooltip placement="top">
							<template slot="title">
								<span>{{text?text:'无'}}</span>
							</template>
							{{text?text:'无'}}
						</a-tooltip>
					</div>
				</template>
			</a-table>
			<a-pagination size="small" v-model="listQuery2.page" :total="total2" :defaultPageSize="listQuery2.limit"
				:show-total="total => `共${total2}条`" show-less-items style="padding: 20px 0;"
				@change="getDetailList()" />
		</a-modal>
		<a-modal :title="''" :visible="payVisible" width="60%" @cancel="payVisible=false" cancelText="取消"
			okText="确认支付" @ok="paySave" :maskClosable="false">
			<div style="color: red;font-weight: bold;width: 100%; text-align: center;font-size: 16px;margin: 0 0 20px;">请您扫二维码进行支付，支付完成后点击确认，并告知“逐光财务”，谢谢！</div>
			<div style="display: flex;align-items: center;width: 100%;justify-content: center;"
				v-if="payForm.list&&payForm.list.length">
				<img class="img" v-for="(item,index) in payForm.list" :src="item" @click="preview(item)" />
			</div>
		</a-modal>
		<a-modal :title="'预览'" :visible="previewVisible" width="90%" @cancel="previewVisible=false" :footer="null"
			:maskClosable="false">
			<img :src="previewImg" alt="" style="width: 100%;">
		</a-modal>
	</div>
</template>

<script>
	import {
		payOrderPage as getList,
		payOrderDetail,
		qrcodeList,
		settlement,
		payOrderBalance
	} from '@/api/peers.js'
	import {
		h
	} from 'vue';
	// 权限判断指令
	import permission from '@/directive/permission/index.js'
	export default {
		directives: {
			permission
		},
		data() {
			return {
				columns: [{
						title: '结算周期',
						dataIndex: 'startDate',
						width: '180px',
						scopedSlots: {
							customRender: 'startDate'
						},
					},
					{
						title: 'PC数量',
						dataIndex: 'pcCount',
						width: '100px'
					},
					{
						title: 'APP数量',
						dataIndex: 'appCount',
						width: '100px'
					},
					{
						title: '微信小程序数量',
						dataIndex: 'wechatCount',
						width: '100px'
					},
					{
						title: '结算金额',
						dataIndex: 'amount',
						width: '100px',
						scopedSlots: {
							customRender: 'amount'
						},
					},
					{
						title: '是否支付',
						dataIndex: 'isPay',
						width: '100px',
						scopedSlots: {
							customRender: 'isPay'
						},
					},
					{
						title: '支付时间',
						dataIndex: 'payTime',
						width: '100px'
					},
					{
						title: '是否确认',
						dataIndex: 'confirmFlag',
						width: '100px',
						scopedSlots: {
							customRender: 'confirmFlag'
						},
					},
					{
						title: '操作',
						dataIndex: 'operation',
						// fixed: 'right',
						scopedSlots: {
							customRender: 'operation'
						},
						width: '120px'
					}
				],
				list: [],
				listQuery: {
					page: 1,
					limit: 10
				},
				selRows: [],
				selRows2: [],
				loading: false,
				columns2: [{
						title: '私编',
						dataIndex: 'myCode',
						width: '110px',
						scopedSlots: {
							customRender: 'myCode'
						},
						sorter: true
					}, {
						title: '项目',
						dataIndex: 'projectName',
						scopedSlots: {
							customRender: 'projectName'
						},
						width: '150px'
					},
					{
						title: '语言框架',
						dataIndex: 'lang',
						width: '120px'
					},
					{
						title: '数据库',
						dataIndex: 'database',
						width: '100px'
					},
					{
						title: '大前端',
						dataIndex: 'bigFrontEnd',
						width: '100px'
					},
					{
						title: '实例编号',
						dataIndex: 'schemaCode',
						scopedSlots: {
							customRender: 'schemaCode'
						},
						width: '120px'
					},
					{
						title: '价格',
						dataIndex: 'price',
						width: '100px',
						scopedSlots: {
							customRender: 'price'
						},
					},
					{
						title: '高校名称',
						dataIndex: 'universityName',
						scopedSlots: {
							customRender: 'universityName'
						},
						width: '100px',
					},
					{
						title: '录单员',
						dataIndex: 'createUserName',
						width: '100px'
					},
					{
						title: '确认收货时间',
						dataIndex: 'confirmTime',
						width: '200px',
						scopedSlots: {
							customRender: 'confirmTime'
						},
					}
				],
				list2: [],
				listQuery2: {
					page: 1,
					limit: 10
				},
				total: 0,
				total2: 0,
				loading2: false,
				showSearch: false,
				nowPeerPayOrderId: 0,
				payForm: {},
				payVisible: false,
				previewImg: '',
				previewVisible: false,
				detailVisible: false,
			}
		},
		created() {
			this.getList()
		},
		computed: {
			userId() {
				return this.$store.state.user.userId
			},
			languageList() {
				return this.$store.state.dict.languageList
			},
			databaseTypeList() {
				return this.$store.state.dict.databaseTypeList
			},
			yesOrNoList() {
				return this.$store.state.dict.yesOrNoList
			},
			orderStatusList() {
				return this.$store.state.dict.orderStatusList
			},
			frontEndList() {
				return this.$store.state.dict.frontEndList
			},
		},
		methods: {
			handleTableChange(pagination, filters, sorter) {
				if (sorter.order) {
					this.listQuery2.sidx = sorter.field
					this.listQuery2.order = sorter.order == 'descend' ? 'desc' : 'asc'
				} else {
					delete this.listQuery2.sidx
					delete this.listQuery2.order
				}
				this.listQuery2.page = 1
				this.getDetailList()
			},
			customRow(record, index) {
				return {
					on: {
						click: (e) => {
							if (this.changeInList(record.settlementId, this.selRows2) === true) {
								this.selRows2 = [record.settlementId]
								this.selRows = [record]
							}
						}
					}
				}
			},
			changeInList(id, arr) {
				for (let x in arr) {
					if (arr[x] == id) {
						return x
					}
				}
				return true
			},
			statusJudge(row) {
				let status = row.isPay
				if (status == 0) {
					return true
				}
				return false
			},
			handleSelectionChange(record, selected, selectedRows, nativeEvent) {
				console.log()
				this.selRows = selected
				this.selRows2 = record
			},
			detailClick(settlementId) {
				this.nowPeerPayOrderId = settlementId
				this.getDetailList()
				this.detailVisible = true
			},
			getDetailList() {
				this.loading2 = true
				payOrderDetail(this.nowPeerPayOrderId, this.listQuery2).then(res => {
					this.list2 = res.data
					this.total2 = Number(res.page.total)
					this.loading2 = false
				})
			},
			getList() {
				this.loading = true
				getList(this.listQuery).then(res => {
					this.total = Number(res.page.total)
					this.list = res.data
					this.loading = false
				})
			},
			search() {
				this.listQuery2.page = 1
				this.getDetailList()
			},
			payClick(settlementId) {
				qrcodeList().then(res => {
					this.payForm = {
						id: settlementId,
						list: res.data
					}
					this.payVisible = true
				})
			},
			paySave() {
				settlement(this.payForm.id).then(res => {
					this.$message.success('操作成功')
					this.payVisible = false
					this.getList()
				})
			},
			preview(url) {
				this.previewImg = url
				this.previewVisible = true
			}
		}
	}
</script>

<style lang="scss" scoped>
	.img {
		width: 240px;
		height: 360px;
		object-fit: cover;
		margin: 0 auto;
	}
	/deep/.ant-table-thead>tr>th {
		// text-align: center;
		font-size: 13px;
		color: #fff;
		background-color: #0083E6;
	}
	
	/deep/.ant-table-tbody>tr:nth-of-type(2n) {
		background-color: rgba(244, 244, 244, 1);
	}
</style>